@use "theme";
@use "reset";
@use "layout";
@use "images";
@use "page";

@use "../node_modules/@ef-global/web-ui/scss/settings/variables/assets" with ($assetsPath: '../../../assets');
@use "../node_modules/@ef-global/web-ui/scss/settings";
@use "../node_modules/@ef-global/web-ui/scss/base";
@use "../node_modules/@ef-global/web-ui/scss/utilities";
@use "../node_modules/@ef-global/web-ui/scss/typography/latin.scss";
@use '../node_modules/@ef-global/web-ui/scss/typography/faces/fonts-latin-extended';
@use "../node_modules/@ef-global/web-ui/scss/components/modal.module.scss";
@use "../node_modules/@ef-global/web-ui/scss/components/surface.module.scss";
@use "../node_modules/@ef-global/web-ui/scss/components/input-text.module.scss";
@use "../node_modules/@ef-global/web-ui/scss/components/input-boolean.module";
@use "../node_modules/@ef-global/web-ui/scss/components/input-select.module";
@use "../node_modules/@ef-global/web-ui/scss/components/list.module.scss";
@use "../node_modules/@ef-global/web-ui/scss/components/card.module";
@use "../node_modules/@ef-global/web-ui/scss/components/table.module";
@use "../node_modules/@ef-global/web-ui/scss/settings/mixins/breakpoints";

body,
button,
input,
select,
textarea {
  font-family: "EF Circular", "Helvetica", "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;
}

a, a:hover, a:active, a:focus {
  color: var(--color-text);
  text-decoration: none;
}

button {
  color: var(--color-text);
}

main {
  a.ef-link {
    border-bottom: 1px solid var(--color-text);
  }
}

.read-more-link {
  cursor: pointer;
  display: inline-flex;
  color: var(--color-text);
  min-height: 24px;
}

.ef-boolean__input:checked ~ .-checkbox:before {
  background-image: url('/inputs/check--active.svg');
}

.ef-boolean__element.-checkbox {
  --color-ui-accent: #cf01a6;
}

.animated-chevrons {
  &.-vertical {
    transform: rotate(90deg);
    &.-inverse {
      transform: rotate(-90deg);
    }
  }


  .chevron-3 {
    opacity: 0;
    transform: translateX(-10px);
  }
}

.read-more-link:hover,
.sort-button:hover {
  .chevron-1, .chevron-2 {
    transform: translateX(10px);
  }

  .chevron-1, .chevron-2, .chevron-3 {
    transition: transform 0.25s ease-in, opacity 0.25s ease-out;
  }

  .chevron-2 {
    opacity: 0;
  }

  .chevron-3 {
    opacity: 1;
    transform: translateX(-1px);
  }
}


.article-main, .profile-main, .partners-content {
  a.ef-link {
    cursor: pointer;
    &:hover {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23191919' stroke-width='1' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
      background-position: 0 100%;
      background-size: auto 3px;
      background-repeat: repeat-x;
      text-decoration: none;
      border-bottom: none;
      padding-bottom:2px;
    }
  }
}

.ef-modal {
  width: 100%;
  padding-top: 40px;
  &.-content {
    padding: 0;
  }
}

.ef-modal__dismiss {
  color: var(--color-text)
}

.ef-text-para.-no-max-width {
  max-width: none;
}


.ef-text-para.-light {
  font-size: 20px;
  line-height: 1.6;
}

.ef-text-para.-light.-small {
  font-size: 16px;
  line-height: 1.5;
}

.coaches-page + .site-footer {
  --footer-bg: var(--color-light-grey);
}

.homepage + .site-footer {
  --footer-bg: #FBF9F1;
  @include breakpoints.breakpoint(2200px) {
    --footer-bg: white;
  }
}

p {
  max-width: none;
}

.section-intro {
  figure {
    @include breakpoints.breakpoint(s only) {
      border-top-right-radius: 0!important;
      border-bottom-right-radius: 0!important;
    }
  }
}

// home - shorten video padding
.section-program {
  @include breakpoints.breakpoint(l) {
    padding-bottom: 45%!important;
  }
  @include breakpoints.breakpoint(s only) {
    .image:nth-child(7) {
      grid-row: 5;
    }
  }
}

// how it works
.section-comprehensive {
  background: url('/comprehensive-bg-top.svg') no-repeat center top / 100%, url('/comprehensive-bg-bottom.svg') no-repeat center bottom / 100%, #191919;

  @include breakpoints.breakpoint(l) {
    &:after {
      content: "";
      width: 1px;
      background-color: rgba(white, 0.1);
      position: absolute;
      left: 50%;
      top: 544px;
      height: calc(100% - 838px);
    }
  }

  .article-image {
    border-radius: 8px;
  }

  .layout-group {
    @include breakpoints.breakpoint(l) {
      &:nth-child(even) {
        position: relative;
        top: 128px;
      }
      &:last-child {
        margin-bottom: 128px;
      }
    }
  }
}

.section-skills-academy-courses {
  background: #ECF1F4;
  & > div:first-child {
    background: url('/skills-academy-courses-bg-top.svg') no-repeat center top / 100%, #191919;
    background-position: 45% -90px;
    background-size: 2400px 160px;
    border-radius: 20px;
  }
}

// foundations
.section-what {
  background: url('/section-bg-foundations.svg') no-repeat center bottom / cover #191919;
  --li-font-size: 20px;
  --li-line-height: 24px;
  --li-font-weight: bold;
  --li-padding: 24px 0;

  @include breakpoints.breakpoint(l) {
    --li-font-size: 24px;
    --li-line-height: 32px;
    --li-font-weight: bold;
    --li-padding: 32px 0;
  }
}

.section-additional {
  @include breakpoints.breakpoint(s only) {
    .image {
      grid-row: 1;
    }
  }
}

.section-need {
  --li-color: #DA2381;
  --li-font-size: 16px;
  --li-line-height: 24px;
  --li-font-weight: bold;
  --li-padding: 24px 0;

  .list-item:last-child {
    --li-color: #191919;
  }
}

.ef-input-w:not(.ef-boolean).-is-invalid:after {
  background-image: url('/inputs/is-invalid.svg')
}
.ef-input-w:not(.ef-boolean).-is-valid:after {
  background-image: url('/inputs/is-valid.svg')
}

.ef-input-w {
  &.-select {
    &:after {
      background-image: url('/inputs/chevron-down.svg');
    }
  }
}

.ef-text-subtitle-small {
  font-weight: 300;
}

.section-girona-camp {
  background: url("/comprehensive-bg-top.svg") no-repeat center top/100%, #191919
}

// section overrides per page for xl screens
.homepage {
  @include breakpoints.breakpoint(2200px) {
    .with-bg {
      .section-bg {
        overflow: hidden;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        max-width: 2200px;
        left: 50%;
        transform: translateX(-50%);
      }

      & + .with-bg {
        .section-bg {
          border-radius: 0;
        }
      }
    }
    .section-Pricing {
      .section-bg {
        border-bottom-right-radius: 16px!important;
        border-bottom-left-radius: 16px!important;
      }
    }
    .section-testimonials {
      padding-bottom: 40%!important;
    }
  }
}

#card-element, #card-expiry-element, #card-cvc-element {
  border-radius: 4px;
  padding: 12px;
  border: 1px solid rgba(25,25,25, 0.5);
  max-height: 48px;
  width: 100%;
  margin-bottom: 24px;
  background: white;
  box-sizing: border-box;

  &.StripeElement--focus {
    border-color: #554166;
  }

  &.StripeElement--invalid {
    border-color: var(--color-notification-warning);
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-xs {
  font-size: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}
