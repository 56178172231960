:root {
  --color-off-white: #FBF9F2;
  --color-dark-grey: #252525;
  --color-light-grey: #F4F4F4;
  --color-pink: #DA2381;
  --color-divider: #{rgba(#252525, 0.3)};
  --color-divider--light: #{rgba(#252525, 0.3)};
  --site-bg: white;
  --article-bg: var(--site-bg);

  .-inv {
    --color-divider: #{rgba(white, 0.5)};
    --color-divider--light: #{rgba(white, 0.3)};
  }
}

