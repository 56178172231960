@use "node_modules/@ef-global/web-ui/scss/settings/mixins/breakpoints";

.logo {
  display: block;
  fill: currentColor;
  height: 40px;
  width: 125px;
  grid-column-start: main-start;
  grid-row-start: header-start;
  cursor: pointer;

  @include breakpoints.breakpoint(m) {
    height: 62px;
    width: 194px;
  }
}