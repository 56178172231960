@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.article-figure {
  display: block;
  padding: 0;
  margin: 0;

  figcaption {
    margin-top: 8px;

    @include breakpoints.breakpoint(m) {
      margin-top: 8px;
    }
  }
}

// 4 col = 5.3333333333
// 2 col = 2.6666666667
.image-grid {
  grid-column: main-start / main-end;
  display: grid;
  grid-template-columns: repeat(6, minmax(10px, 1fr));
  grid-gap: 8px;

  h6.fallback {
    grid-column: 1 / -1;
    max-width: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(black, 0.025)
  }

  .article-figure {
    grid-column: 1 / -1;

    & + .article-figure {
      margin-top: 32px;

      @include breakpoints.breakpoint(m) {
        margin-top: 0;
      }
    }
  }

  &.-single-offset-right {
    grid-column: inner-start / offset-end;
  }

  &.-single-offset-left {
    grid-column: offset-start / inner-end;
  }

  &.-double-wide-right {
    @include breakpoints.breakpoint(m) {
      .article-figure:nth-child(1) {
        grid-column: 1 / 3;
      }
      .article-figure:nth-child(2) {
        grid-column: 3 / 7;
      }
    }
  }

  &.-double-wide-left {
    @include breakpoints.breakpoint(m) {
      .article-figure:nth-child(1) {
        grid-column: 1 / 5;
      }
      .article-figure:nth-child(2) {
        grid-column: 5 / 7;
      }
    }
  }

  &.-double-wide-equal {
    @include breakpoints.breakpoint(m) {
      .article-figure:nth-child(1) {
        grid-column: 1 / 4;
      }
      .article-figure:nth-child(2) {
        grid-column: 4 / 7;
      }
    }
  }

  &.-double-tall-left {
    @include breakpoints.breakpoint(m) {
      .article-figure:nth-child(1) {
        grid-column: 1 / 5;
      }
      .article-figure:nth-child(2) {
        grid-column: 5 / 7;
      }
    }
  }

  &.-double-tall-right {
    @include breakpoints.breakpoint(m) {
      .article-figure:nth-child(1) {
        grid-column: 1 / 3;
      }
      .article-figure:nth-child(2) {
        grid-column: 3 / 7;
      }
    }
  }

  &.-triple {
    @include breakpoints.breakpoint(m) {
      .article-figure:nth-child(1) {
        grid-column: 1 / 5;
      }
      .article-figure:nth-child(2) {
        grid-column: 5 / 7;
      }
      .article-figure:nth-child(3) {
        grid-column: 1 / -1;
      }
    }
  }

  &.-quadruple {
    @include breakpoints.breakpoint(m) {
      .article-figure:nth-child(1) {
        grid-column: 1 / 5;
      }
      .article-figure:nth-child(2) {
        grid-column: 5 / 7;
      }
      .article-figure:nth-child(3) {
        grid-column: 1 / 3;
      }
      .article-figure:nth-child(4) {
        grid-column: 3 / 7;
      }
    }
  }
}