@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.siteHeader {
  grid-area: header;
  z-index: 9;
}

.proCyclingHeader__inner {
  background-color: var(--header-bg, var(--article-bg));
  transition: background-color 0.2s ease;
  padding: 24px 0;
  grid-template-rows: [header-start] 1fr [header-end];
  height: 89px;

  @include breakpoints.breakpoint(m) {
    height: 111px;
  }

  .measure-component {
    @include breakpoints.breakpoint(s) {
      grid-area: header-start / 4 / header-end / main-end;
    }
    @include breakpoints.breakpoint(m) {
      grid-area: header-start / 3 / header-end / main-end;
    }
    @include breakpoints.breakpoint(l) {
      grid-area: header-start / 5 / header-end / main-end;
    }
  }
}

.proCyclingNav {
  display: block;
}

.globalHeader {
  height: 48px;

  @include breakpoints.breakpoint(m) {
    height: 56px;
  }
}