@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.pro-cycling-page-header {
  padding: 32px 0;
  grid-column-start: main-start;
  grid-column-end: main-end;
  display: grid;
  grid-gap: 0 16px;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  border-top: 1px solid var(--color-dark-grey);
  border-bottom: 1px solid var(--color-divider);
  align-items: center;
  margin-bottom: 32px;

  @include breakpoints.breakpoint(m) {
    margin-bottom: 64px;
  }

  &.-with-footer {
    padding-bottom: 0;
    margin-bottom: 32px;
  }

  &__title {
    max-width: 80%!important;

    @include breakpoints.breakpoint(l) {
      max-width: 66%!important;
    }
  }

  &__intro {
    grid-column: 1 / -1;
  }

  &__footer {
    grid-column: 1 / -1;
    border-top: 1px solid var(--color-divider);
    margin-top: 32px;
    padding: 16px 0;
  }

  @include breakpoints.breakpoint(m) {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
    padding: 32px 0;

    &__title {
      grid-column: 1 / 3;

      .-calendar & {
        grid-column: 1 / -1;
        max-width: 66%!important;
      }
    }

    &__intro {
      grid-column: 3 / -1;
      text-align: right;
    }

    &__footer {
      grid-row: 2;
    }
  }
}

.pro-cycling-page {
  display: grid;
  grid-template-rows: min-content 1fr;

  &.-racing,
  &.-tips-recipes,
  &.-culture
  &.-videos {
    --article-bg: var(--color-off-white)
  }
  &.-racing,
  &.-tips-recipes,
  &.-culture {
    + .pro-cycling-footer {
      padding-top: 64px;
      --article-bg: var(--color-off-white)
    }
  }
}

.pro-cycling-page-content {
  grid-column: main-start / main-end;
}

.text-divider {
  margin: 0 8px;

  @include breakpoints.breakpoint(l) {
    margin: 3px 24px;
    width: 4px;
    height: 4px;
    display: inline-flex;
    background-color: var(--color-dark-grey);
  }
}

.team-page-sort {
  grid-column: main-start / main-end;
  border-bottom: 1px solid var(--color-dark-grey);
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 16px;

  @include breakpoints.breakpoint(m) {
    margin-bottom: 24px;
  }
}

.button-content {
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 8px;
  }
}

.team-content-grid {
  grid-column: main-start / main-end;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, minmax(10px, 1fr));

  @include breakpoints.breakpoint(m) {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
  }

  @include breakpoints.breakpoint(l) {
    grid-gap: 16px 24px;
    grid-template-columns: repeat(4, minmax(10px, 1fr));
  }
}



.index-content-grid, .story-cards:not(.-no-border) {
  .index-item__title {
    border-bottom: 1px solid var(--color-divider);
    transition: border-color 0.3s cubic-bezier(.4,0,.6,1);

    &:hover & {
        border-color: var(--color-ink-black);
    }
  }
}

.index-item__title {
  padding: 8px 0;
  font-weight: bold;
  flex-grow: 1;

  @include breakpoints.breakpoint(l) {
    flex-grow: unset;
    padding: 16px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.index-item__title p {
  @include breakpoints.breakpoint(l) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.index-item, .story-card {
  will-change: transform;
  display: flex;
  flex-direction: column;

  &.-featured {
    grid-column: main-start / main-end;
    margin-bottom: 16px;
  }

  &, &:hover, &:active, &:focus {
    text-decoration: none;
    color: var(--color-text);
  }
}

.story-card__text {
  display: flex;
  align-items: center;
  padding: 8px 0;
  font-size: 14px;
  line-height: 16px;

  @include breakpoints.breakpoint(l) {
    padding: 16px 0;
  }

  .ef-flag {
    width: 28px;
    margin-right: 8px;

    @include breakpoints.breakpoint(l) {
      margin-right: 16px;
    }
  }
}

.story-card.video-card {
  cursor: pointer;

  .video-card__wrap {
    position: relative;
  }

  .video-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.6, 1);

    &:hover {
      background-color: rgba(0,0,0,0.3);
    }
  }
}

a.story-card, .index-item {
  cursor: pointer;
  .article-image {
    overflow: hidden;
    img {
      will-change: transform;
      transform: scale(1);
      transition: transform 0.3s cubic-bezier(.4,0,.6,1)
    }
  }
  &:hover {
    img {transform: scale(1.05)}
    .index-item__title { border-color: var(--color-text)}
  }
}


.index-page-divider {
  height: 1px;
  grid-column: main-start / main-end;
  background-color: var(--color-divider);
  margin-top: 64px;
  margin-bottom: 64px;
}

.index-read-more {
  grid-column: 1 / -1;
  display: flex;
  align-items: center;

  hr {
    --color-divider: var(--color-text);
    flex: 1;
  }

  .read-more-link {
    margin: 0 16px;
  }
}

.page-main {
  background-color: var(--article-bg);

  > * {
    grid-column: main-start / main-end;
  }

  .ef-list {
    list-style: circle;
  }
}

.layout-site.checkout {
  --site-bg: #FAFBFD;
  --footer-bg: transparent;
  --header-bg: transparent;
  --global-header-bg: white;
}

.layout-site.how-it-works {
  --header-bg: #FAFBFD;
  --footer-bg: #f4f4f4;

  > header {
    --site-bg: #FAFBFD;
  }
}

.layout-site.pricing {
  --footer-bg: #E4F0F7;
}

.layout-site.blog {
  --footer-bg: transparent;
  --header-bg: transparent;
  --site-bg: #FBF9F1;
  --article-bg: #FBF9F1;
}



