@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.layout-site {
  min-height: 100vh;
  min-width: 320px;
  display: grid;
  grid-template-rows: 137px auto min-content;
  grid-template-areas:
      "header"
      "content"
      "footer";
  background: var(--site-bg, white);
  transition: background-color 0.2s ease;

  @include breakpoints.breakpoint(m) {
    grid-template-rows: 167px auto min-content;
  }
}

.layout-grid {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns:
  [bounds-start] 24px
  [main-start inner-start offset-start col-1-start col-2-start col-3-start] minmax(1px, 1fr)
  [col-1-end col-2-end col-3-end col-4-start-outer col-5-start-outer col-6-start-outer] 16px
  [col-4-start col-5-start col-6-start] minmax(1px, 1fr)
  [col-4-end col-5-end col-6-end col-7-start-outer col-8-start-outer col-9-start-outer] 16px
  [col-7-start col-8-start col-9-start] minmax(1px, 1fr)
  [col-7-end col-8-end col-9-end col-10-start-outer col-11-start-outer col-12-start-outer] 16px
  [col-10-start col-11-start col-12-start] minmax(1px, 1fr)
  [col-11-end col-12-end main-end inner-end offset-end] 24px
  [bounds-end];

  @include breakpoints.breakpoint(m) {
    grid-template-columns: [bounds-start] 32px
    [main-start inner-start offset-start col-1-start] minmax(1px, 1fr)
    [col-1-end col-2-start-outer] 16px
    [col-2-start] minmax(1px, 1fr)
    [col-2-end col-3-start-outer] 16px
    [col-3-start] minmax(1px, 1fr)
    [col-3-end col-4-start-outer] 16px
    [col-4-start] minmax(1px, 1fr)
    [col-4-end col-5-start-outer] 16px
    [col-5-start] minmax(1px, 1fr)
    [col-5-end col-6-start-outer] 16px
    [col-6-start] minmax(1px, 1fr)
    [col-6-end col-7-start-outer] 16px
    [col-7-start] minmax(1px, 1fr)
    [col-7-end col-8-start-outer] 16px
    [col-8-start] minmax(1px, 1fr)
    [col-8-end col-9-start-outer] 16px
    [col-9-start] minmax(1px, 1fr)
    [col-9-end col-10-start-outer] 16px
    [col-10-start] minmax(1px, 1fr)
    [col-10-end col-11-start-outer] 16px
    [col-11-start] minmax(1px, 1fr)
    [col-11-end col-12-start-outer] 16px
    [col-12-start] minmax(1px, 1fr)
    [col-12-end main-end inner-end offset-end]
    32px [bounds-end];
  }

  @include breakpoints.breakpoint(l) {
    grid-template-columns: [bounds-start] minmax(32px, auto)
    [main-start offset-start col-1-start] minmax(10px, 78px)
    [col-1-end col-2-start-outer] 24px
    [col-2-start inner-start] minmax(10px, 78px)
    [col-2-end col-3-start-outer] 24px
    [col-3-start] minmax(10px, 78px)
    [col-3-end col-4-start-outer] 24px
    [col-4-start] minmax(10px, 78px)
    [col-4-end col-5-start-outer] 24px
    [col-5-start] minmax(10px, 78px)
    [col-5-end col-6-start-outer] 24px
    [col-6-start] minmax(10px, 78px)
    [col-6-end col-7-start-outer] 24px
    [col-7-start] minmax(10px, 78px)
    [col-7-end col-8-start-outer] 24px
    [col-8-start] minmax(10px, 78px)
    [col-8-end col-9-start-outer] 24px
    [col-9-start] minmax(10px, 78px)
    [col-9-end col-10-start-outer] 24px
    [col-10-start] minmax(10px, 78px)
    [col-10-end col-11-start-outer] 24px
    [col-11-start] minmax(10px, 78px)
    [col-11-end inner-end col-12-start-outer] 24px
    [col-12-start] minmax(10px, 78px)
    [col-12-end main-end offset-end]
    minmax(32px, auto) [bounds-end];
  }

  @include breakpoints.breakpoint(1440px) {
    grid-template-columns: [bounds-start] minmax(32px, auto)
    [offset-start] minmax(10px, 102px)
    [main-start col-1-start] minmax(10px, 78px)
    [col-1-end col-2-start-outer] 24px
    [col-2-start] minmax(10px, 78px)
    [col-2-end col-3-start-outer] 24px
    [col-3-start inner-start] minmax(10px, 78px)
    [col-3-end col-4-start-outer] 24px
    [col-4-start] minmax(10px, 78px)
    [col-4-end col-5-start-outer] 24px
    [col-5-start] minmax(10px, 78px)
    [col-5-end col-6-start-outer] 24px
    [col-6-start] minmax(10px, 78px)
    [col-6-end col-7-start-outer] 24px
    [col-7-start] minmax(10px, 78px)
    [col-7-end col-8-start-outer] 24px
    [col-8-start] minmax(10px, 78px)
    [col-8-end col-9-start-outer] 24px
    [col-9-start] minmax(10px, 78px)
    [col-9-end col-10-start-outer] 24px
    [col-10-start] minmax(10px, 78px)
    [col-10-end inner-end col-11-start-outer] 24px
    [col-11-start] minmax(10px, 78px)
    [col-11-end col-12-start-outer] 24px
    [col-12-start] minmax(10px, 78px)
    [col-12-end main-end]
    minmax(10px, 102px) [offset-end]
    minmax(32px, auto) [bounds-end];
  }

  @include breakpoints.breakpoint(2160px) {
    grid-template-columns: [bounds-start] minmax(32px, auto)
    [offset-start] minmax(10px, auto)
    [main-start col-1-start] minmax(10px, 78px)
    [col-1-end col-2-start-outer] 24px
    [col-2-start] minmax(10px, 78px)
    [col-2-end col-3-start-outer] 24px
    [col-3-start inner-start] minmax(10px, 78px)
    [col-3-end col-4-start-outer] 24px
    [col-4-start] minmax(10px, 78px)
    [col-4-end col-5-start-outer] 24px
    [col-5-start] minmax(10px, 78px)
    [col-5-end col-6-start-outer] 24px
    [col-6-start] minmax(10px, 78px)
    [col-6-end col-7-start-outer] 24px
    [col-7-start] minmax(10px, 78px)
    [col-7-end col-8-start-outer] 24px
    [col-8-start] minmax(10px, 78px)
    [col-8-end col-9-start-outer] 24px
    [col-9-start] minmax(10px, 78px)
    [col-9-end col-10-start-outer] 24px
    [col-10-start] minmax(10px, 78px)
    [col-10-end inner-end col-11-start-outer] 24px
    [col-11-start] minmax(10px, 78px)
    [col-11-end col-12-start-outer] 24px
    [col-12-start] minmax(10px, 78px)
    [col-12-end main-end]
    minmax(10px, auto) [offset-end]
    minmax(32px, auto) [bounds-end];
  }
}

.offset-grid {
  display: grid;
  @include breakpoints.breakpoint(2160px) {
    grid-template-columns:
    [bounds-start] auto
    [offset-start] minmax(10px, 102px)
    [main-start col-1-start] minmax(10px, 78px)
    [col-1-end col-2-start-outer] 24px
    [col-2-start inner-start] minmax(10px, 78px)
    [col-2-end col-3-start-outer] 24px
    [col-3-start] minmax(10px, 78px)
    [col-3-end col-4-start-outer] 24px
    [col-4-start] minmax(10px, 78px)
    [col-4-end col-5-start-outer] 24px
    [col-5-start] minmax(10px, 78px)
    [col-5-end col-6-start-outer] 24px
    [col-6-start] minmax(10px, 78px)
    [col-6-end col-7-start-outer] 24px
    [col-7-start] minmax(10px, 78px)
    [col-7-end col-8-start-outer] 24px
    [col-8-start] minmax(10px, 78px)
    [col-8-end col-9-start-outer] 24px
    [col-9-start] minmax(10px, 78px)
    [col-9-end col-10-start-outer] 24px
    [col-10-start] minmax(10px, 78px)
    [col-10-end col-11-start-outer] 24px
    [col-11-start] minmax(10px, 78px)
    [col-11-end inner-end col-12-start-outer] 24px
    [col-12-start] minmax(10px, 78px)
    [col-12-end main-end inner-end]
    minmax(10px, 102px) [offset-end]
    auto [bounds-end]
  }
}

.inner-grid {
  display: grid;

  @include breakpoints.breakpoint(l) {
    grid-template-columns:
    [bounds-start offset-start main-start col-1-start] minmax(10px, 78px)
    [col-1-end col-2-start-outer] 24px
    [col-2-start inner-start] minmax(10px, 78px)
    [col-2-end col-3-start-outer] 24px
    [col-3-start] minmax(10px, 78px)
    [col-3-end col-4-start-outer] 24px
    [col-4-start] minmax(10px, 78px)
    [col-4-end col-5-start-outer] 24px
    [col-5-start] minmax(10px, 78px)
    [col-5-end col-6-start-outer] 24px
    [col-6-start] minmax(10px, 78px)
    [col-6-end col-7-start-outer] 24px
    [col-7-start] minmax(10px, 78px)
    [col-7-end col-8-start-outer] 24px
    [col-8-start] minmax(10px, 78px)
    [col-8-end col-9-start-outer] 24px
    [col-9-start] minmax(10px, 78px)
    [col-9-end col-10-start-outer] 24px
    [col-10-start] minmax(10px, 78px)
    [col-10-end col-11-start-outer] 24px
    [col-11-start] minmax(10px, 78px)
    [col-11-end inner-end col-12-start-outer] 24px
    [col-12-start] minmax(10px, 78px)
    [col-12-end main-end inner-end offset-end bounds-end]
  }
}

.layout-divider {
  height: 0;
  border-bottom: 1px solid var(--color-divider)
}

.layout-site {
    --site-bg: var(--color-white);
    --article-bg: var(--color-white);

  &.elite {
    --site-bg: black;
    --color-divider: rgba(255,255,255,0.3);

    header {
      --color-text: white;
    }
  }
}
